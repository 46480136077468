<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <span style="display: none">{{ leftClick }} {{ rightClick }}</span>
        <div v-if="!isEnabledNow" class="grid-click-security">
          <div class="left-click" @dblclick="leftClick += 1"></div>
          <div class="right-click" @dblclick="rightClick += 1"></div>
        </div>

        <v-card v-if="isEnabledNow">
          <v-card-title class="title">
            Schools
            <v-spacer></v-spacer>

            <add-button
              :on-current-batch="false"
              permission="school-create"
              @action="$router.push({ name: 'schools-form' })"
              >Register New School
            </add-button>
            <!--<v-btn outlined @click="" ma-0 small>New School</v-btn>-->
          </v-card-title>

          <v-card outlined>
            <v-card-title class="title">
              <v-flex xs7>
                <v-text-field
                  outlined
                  dense
                  hint="Atleast 3 characters"
                  color="primary"
                  class="pa-0 mb-0"
                  label="Search by Name, Email or Phone"
                  v-model="search"
                  append-icon="search"
                  @input="searchSchool"
                />
              </v-flex>
              <v-flex xs4>
                <v-select
                  outlined
                  dense
                  :items="types"
                  v-model="type"
                  label="Types"
                  class="pa-0 mb-0"
                ></v-select>
              </v-flex>
              <v-flex xs1>
                <v-btn small color="primary" outlined @click="clearFilter">
                  Clear
                </v-btn>
              </v-flex>
            </v-card-title>
          </v-card>
          <br />
          <v-data-table
            :headers="headers"
            :items="schools.data"
            :options.sync="pagination"
            :server-items-length="schools.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + schools.meta.from }}</td>
                <td align="left" class="text-xs-left name-field">
                  <v-list-item class="pl-0" style="padding-left: 0;">
                    <v-list-item-avatar style="padding-left: 0">
                      <img :src="item.logo" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <small
                          ><strong>{{ item.name.toUpperCase() }}</strong></small
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text text--lighten-1">
                        <small>{{ item.slogan }}</small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </td>
                <td class="text-xs-left">{{ item.short_code }}</td>
                <td class="text-xs-left">
                  <v-icon small>open_in_new</v-icon> &nbsp;
                  <a target="_blank" :href="'https://' + item.domain">{{
                    item.domain
                  }}</a>
                </td>
                <td class="text-right">
                  <view-button
                    label="Billing Monitor"
                    permission="school-update"
                    @click.native="
                      $router.push({
                        name: 'school-billing-monitor',
                        params: { id: item.id },
                      })
                    "
                  />
                  &nbsp;
                  <br />

                  <view-button
                    label="Data Entry"
                    permission="school-update"
                    @click.native="
                      $router.push({
                        name: 'school-data-entry',
                        params: { id: item.id },
                      })
                    "
                  />
                  &nbsp; <br />
                  <view-button
                    permission="school-update"
                    @click.native="
                      $router.push({
                        name: 'school-setting',
                        params: { id: item.id },
                      })
                    "
                  />
                  <br />
                  <edit-button
                    permission="school-update"
                    @agree="
                      $router.push({
                        name: 'schools-edit',
                        params: { id: item.id },
                      })
                    "
                  />
                  <!--<delete-button permission="school-delete" @agree=""/>-->
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Mixins from "@/library/Mixins";
let typingTimer;
let doneTypingInterval = 400;

export default {
  mixins: [Mixins],

  data: () => ({
    leftClick: 1,
    rightClick: 2,
    filter: false,
    search: "",

    headers: [
      { text: "#", align: "center", value: "id", width: 20, sortable: false },
      { text: "Name", align: "left", value: "name", width: 80 },
      { text: "SC", align: "left", value: "short_code", width: 80 },
      { text: "Domain", align: "left", value: "domain", width: 200 },
      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
    schools: {
      data: [],
      meta: {},
    },
    types: [
      { value: "school", text: "School" },
      { value: "account", text: "Account" },
      { value: "lekhangkan", text: "Lekhangkan" },
      { value: "sms", text: "SMS" },
    ],
    type: "",
  }),
  watch: {
    pagination: function() {
      this.get();
    },
    type: function(v) {
      this.get();
    },
    search: function(val) {
      if (!val) return;

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
        $this.get(val);
      }, doneTypingInterval);
    },
  },
  computed: {
    isEnabledNow() {
      return this.leftClick === 1 && this.rightClick === 2;
    },
  },
  methods: {
    get(params) {
      let extraParams = "flag=" + this.type + "&search=" + this.search;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      console.log(query);
      this.$rest.get(`/api/company${query}`).then(({ data }) => {
        this.schools = data;
        this.pagination.totalItems = data.meta.total;
      });
    },
    searchSchool() {},
    clearFilter() {
      this.search = "";
      this.type = "";
      this.get();
    },
  },
};
</script>

<style lang="scss">
.name-field .v-list__tile {
  padding: 0;
}

.grid-click-security {
  min-height: 30px;
  background-color: #eee;
  display: flex;
  justify-content: space-between;

  .left-click {
    height: 30px;
    width: 30px;
    background-color: #f3f3f3;
  }

  .right-click {
    height: 30px;
    width: 30px;
    background-color: #f3f3f3;
  }
}
</style>
