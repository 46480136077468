var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","pa-0":"","relative":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.leftClick)+" "+_vm._s(_vm.rightClick))]),(!_vm.isEnabledNow)?_c('div',{staticClass:"grid-click-security"},[_c('div',{staticClass:"left-click",on:{"dblclick":function($event){_vm.leftClick += 1}}}),_c('div',{staticClass:"right-click",on:{"dblclick":function($event){_vm.rightClick += 1}}})]):_vm._e(),(_vm.isEnabledNow)?_c('v-card',[_c('v-card-title',{staticClass:"title"},[_vm._v(" Schools "),_c('v-spacer'),_c('add-button',{attrs:{"on-current-batch":false,"permission":"school-create"},on:{"action":function($event){return _vm.$router.push({ name: 'schools-form' })}}},[_vm._v("Register New School ")])],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_c('v-flex',{attrs:{"xs7":""}},[_c('v-text-field',{staticClass:"pa-0 mb-0",attrs:{"outlined":"","dense":"","hint":"Atleast 3 characters","color":"primary","label":"Search by Name, Email or Phone","append-icon":"search"},on:{"input":_vm.searchSchool},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-select',{staticClass:"pa-0 mb-0",attrs:{"outlined":"","dense":"","items":_vm.types,"label":"Types"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-flex',{attrs:{"xs1":""}},[_c('v-btn',{attrs:{"small":"","color":"primary","outlined":""},on:{"click":_vm.clearFilter}},[_vm._v(" Clear ")])],1)],1)],1),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.schools.data,"options":_vm.pagination,"server-items-length":_vm.schools.meta.total},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + _vm.schools.meta.from))]),_c('td',{staticClass:"text-xs-left name-field",attrs:{"align":"left"}},[_c('v-list-item',{staticClass:"pl-0",staticStyle:{"padding-left":"0"}},[_c('v-list-item-avatar',{staticStyle:{"padding-left":"0"}},[_c('img',{attrs:{"src":item.logo}})]),_c('v-list-item-content',[_c('v-list-item-title',[_c('small',[_c('strong',[_vm._v(_vm._s(item.name.toUpperCase()))])])]),_c('v-list-item-subtitle',{staticClass:"grey--text text--lighten-1"},[_c('small',[_vm._v(_vm._s(item.slogan))])])],1)],1)],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.short_code))]),_c('td',{staticClass:"text-xs-left"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("open_in_new")]),_vm._v(" "),_c('a',{attrs:{"target":"_blank","href":'https://' + item.domain}},[_vm._v(_vm._s(item.domain))])],1),_c('td',{staticClass:"text-right"},[_c('view-button',{attrs:{"label":"Billing Monitor","permission":"school-update"},nativeOn:{"click":function($event){return _vm.$router.push({
                      name: 'school-billing-monitor',
                      params: { id: item.id },
                    })}}}),_vm._v(" "),_c('br'),_c('view-button',{attrs:{"label":"Data Entry","permission":"school-update"},nativeOn:{"click":function($event){return _vm.$router.push({
                      name: 'school-data-entry',
                      params: { id: item.id },
                    })}}}),_vm._v(" "),_c('br'),_c('view-button',{attrs:{"permission":"school-update"},nativeOn:{"click":function($event){return _vm.$router.push({
                      name: 'school-setting',
                      params: { id: item.id },
                    })}}}),_c('br'),_c('edit-button',{attrs:{"permission":"school-update"},on:{"agree":function($event){return _vm.$router.push({
                      name: 'schools-edit',
                      params: { id: item.id },
                    })}}})],1)])]}}],null,false,207749618)},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }